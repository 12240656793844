import { ExpandLess, ExpandMore } from "@mui/icons-material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import EditIco from "../../../assets/icons/EditIco";
import FileIco from "../../../assets/icons/FileIco";
import FileSearchIco from "../../../assets/icons/FileSearchIco";
import HomeLineIco from "../../../assets/icons/HomeLineIco";
import PieChartIco from "../../../assets/icons/PieChartIco";
import ResumeIntelligenceIco from "../../../assets/icons/ResumeIntelligenceIco";
import ToolIco from "../../../assets/icons/ToolIco";
import UsersIco from "../../../assets/icons/UsersIco";
import LogoMark from "../../../assets/images/Logomark.png";
import CustomMenuItem from "../../../component/Menu";
import { CommonGrid2 } from "../../../modules/common-components/StyledComponents";
import { CommonTippy } from "../../../modules/jobs/style";

const Sidebar = ({ open, setOpen, handleNavigation }) => {
  const theme = useTheme();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const [anchorEl, setAnchorEl] = useState(null);
  const [expandedItems, setExpandedItems] = useState({});
  const SettingMenu = Boolean(anchorEl);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleDrawer = () => {
    setOpen((prev) => !prev);
  };

  const handleExpandToggle = (index) => {
    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleClickDot = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuData = [
    {
      title: "Settings",
      onClick: () => handleNavigation("/settings"),
    },
    // { title: "Add to an Active Job", image: <FileAddIco />, onClick: handleOpenActiveJobDialog, },
  ];

  const SidebarItems = [
    {
      name: "Dashboard",
      path: "/dashboard",
      icon: <HomeLineIco />,
    },
    {
      name: "Resume Intelligence",
      path: "/reusme-intelligence/create-candidate-resume",
      icon: <ResumeIntelligenceIco />,
    },
    {
      name: "Candidates",
      path: "/candidates",
      icon: <UsersIco />,
    },
    {
      name: "Reports",
      path: "/reports",
      icon: <PieChartIco />,
    },
    {
      name: "Jobs",
      path: "/jobs",
      icon: <FileIco />,
    },
    ...(!open
      ? [
          {
            name: "Quick Match",
            path: "/tools/quick-match",
            icon: <FileSearchIco />,
          },
          {
            name: "Role Writer",
            path: "/role-writer",
            icon: <EditIco />,
          },
        ]
      : [
          {
            name: "Tools",
            icon: <ToolIco />,
            children: [
              {
                name: "Quick Match",
                path: "/tools/quick-match",
                icon: <FileSearchIco />,
              },
              {
                name: "Role Writer",
                path: "/role-writer",
                icon: <EditIco />,
              },
            ],
          },
        ]),
  ];

  return (
    <Drawer
      variant={open && !lgUp ? "temporary" : "permanent"}
      open={open}
      anchor="left"
      onClose={toggleDrawer}
      sx={{
        "& .MuiDrawer-paper": {
          width: open ? 312 : theme.spacing(8),
          transition: theme.transitions.create("width", {
            duration: theme.transitions.duration.standard,
          }),
          overflowX: "hidden",
        },
        position: "relative",
      }}
    >
      <CommonGrid2
        container
        direction="column"
        height={"100vh"}
        padding={"16px"}
        margin={0}
      >
        <CommonGrid2
          item
          display={"flex"}
          flexDirection={"row"}
          gap={"10px"}
          marginBottom={"10px"}
          marginTop={"10px"}
          marginLeft={"5px"}
          width={"max-content"}
          sx={{ cursor: "pointer" }}
          onClick={() => handleNavigation("/auth/login")}
        >
          <img src={LogoMark} width={"32px"} height={"32px"} alt="logo" />
          <Typography fontWeight={600} fontSize={"24px"} color={"#101828"}>
            Talairo
          </Typography>
        </CommonGrid2>

        <CommonGrid2 item flexGrow={1} overflowY={"auto"}>
          <List
            sx={{ width: "100%", bgcolor: "background.paper" }}
            component="nav"
          >
            {SidebarItems.map((item, index) => (
              <React.Fragment key={item.name}>
                <ListItemButton
                  onClick={() =>
                    item.children
                      ? handleExpandToggle(index)
                      : handleNavigation(item.path)
                  }
                  selected={location.pathname.includes(item.path)}
                  sx={{
                    padding: "10px",
                    background: location.pathname.includes(item.path)
                      ? "rgb(230 230 230)"
                      : "",
                    borderRadius: "10px",
                    boxShadow:
                      location.pathname.includes(item.path) &&
                      "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                  }}
                >
                  <CommonTippy
                    content={item.name}
                    placement="right"
                    disabled={open}
                    color="#7F56D9"
                    fontWeight={600}
                    fontSize={"13px"}
                  >
                    <ListItemIcon
                      sx={{
                        justifyContent: "flex-start",
                        minWidth: "40px",
                      }}
                    >
                      {React.cloneElement(item.icon, {
                        color: location.pathname.includes(item.path)
                          ? "#7F56D9"
                          : "#667085",
                      })}
                    </ListItemIcon>
                  </CommonTippy>
                  <ListItemText
                    primary={
                      <Typography
                        variant="body1"
                        fontWeight={
                          location.pathname.includes(item.path) ? 700 : 600
                        }
                        color={
                          location.pathname.includes(item.path)
                            ? "#7F56D9"
                            : "#344054"
                        }
                        fontSize={"16px"}
                      >
                        {item.name}
                      </Typography>
                    }
                  />
                  {item.children &&
                    (expandedItems[index] ? <ExpandLess /> : <ExpandMore />)}
                </ListItemButton>
                {item.children && (
                  <Collapse
                    in={expandedItems[index]}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List
                      component="div"
                      disablePadding
                      sx={{ marginLeft: open ? "2rem" : "3rem" }}
                    >
                      {item.children.map((child) => (
                        <ListItemButton
                          key={child.name}
                          onClick={() => handleNavigation(child.path)}
                          sx={{
                            background: location.pathname.includes(child.path)
                              ? "rgb(230 230 230)"
                              : "",
                            borderRadius: "10px",
                            padding: "8px 16px",
                          }}
                        >
                          <ListItemIcon sx={{ minWidth: "40px" }}>
                            {React.cloneElement(child.icon, {
                              color: location.pathname.includes(child.path)
                                ? "#7F56D9"
                                : "#667085",
                            })}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                fontSize={"16px"}
                                variant="body1"
                                fontWeight={
                                  location.pathname.includes(child.path)
                                    ? 700
                                    : 600
                                }
                                color={
                                  location.pathname.includes(child.path)
                                    ? "#7F56D9"
                                    : "#344054"
                                }
                              >
                                {child.name}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      ))}
                    </List>
                  </Collapse>
                )}
              </React.Fragment>
            ))}
          </List>
        </CommonGrid2>

        {/* Footer Section */}
        {open && (
          <Grid item>
            <Box
              sx={{
                backgroundColor: "#F9FAFB",
                border: "1px solid #E5E7EB",
                borderRadius: "8px",
                padding: 2,
                mb: 2,
                display: "flex",
                gap: "10px",
                flexDirection: "column",
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                New features available!
              </Typography>
              <Typography
                sx={{ color: "#475467", fontSize: "14px", fontWeight: 400 }}
              >
                Check out the new dashboard view. Pages now load faster!
              </Typography>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "10px" }}
              >
                <Button
                  variant="text"
                  sx={{
                    fontSize: "14px",
                    textTransform: "none",
                    color: "#475467",
                    fontWeight: 600,
                    padding: "0px",
                  }}
                >
                  Dismiss
                </Button>
                <Button
                  variant="text"
                  sx={{
                    fontSize: "14px",
                    textTransform: "none",
                    color: "#6941C6",
                    fontWeight: 600,
                    padding: "0px",
                  }}
                >
                  What’s new?
                </Button>
              </div>
            </Box>
          </Grid>
        )}

        {/* User Profile Section */}
        <CommonGrid2 item>
          <Divider />
          <CommonGrid2
            display={"flex"}
            flexDirection={"row"}
            gap={"10px"}
            justifyContent={"space-between"}
            alignItems={"flex-start"}
            marginTop={"24px"}
            marginBottom={"20px"}
          >
            <CommonGrid2 display={"flex"} flexDirection={"row"} gap={"10px"}>
              <Avatar
                alt="Olivia Rhye"
                src="https://mui.com/static/images/avatar/1.jpg"
                sx={{ width: 40, height: 40 }}
              />
              <CommonGrid2 item>
                <Typography variant="body1" fontWeight={"bold"}>
                  Olivia Rhye
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  olivia@untitledui.com
                </Typography>
              </CommonGrid2>
            </CommonGrid2>

            <IconButton>
              <MoreVertOutlinedIcon
                style={{ cursor: "pointer" }}
                aria-controls={SettingMenu ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={SettingMenu ? "true" : undefined}
                onClick={(event) => handleClickDot(event)}
              />
              <CustomMenuItem
                open={SettingMenu}
                anchorEl={anchorEl}
                handleMenuClose={handleMenuClose}
                menuData={menuData}
              />
            </IconButton>
          </CommonGrid2>
        </CommonGrid2>
      </CommonGrid2>
    </Drawer>
  );
};

export default Sidebar;
