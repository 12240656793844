import { Download, ExpandMore } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  FormControl,
  Grid2,
  Link,
  Menu,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BookMarkIco from "../../../../assets/icons/BookMarkIco";
import BriefCaseIco from "../../../../assets/icons/BriefCaseIco";
import CalenderIco from "../../../../assets/icons/CalenderIco";
import ClipBoardCheck from "../../../../assets/icons/ClipBoardCheckIco";
import CopyIco from "../../../../assets/icons/CopyIco";
import DotsVerticalIco from "../../../../assets/icons/DotsVerticalIco";
import ScalesIco from "../../../../assets/icons/ScalesIco";
import ShareIco from "../../../../assets/icons/ShareIco";
import UsIco from "../../../../assets/icons/UsIco";
import XCircleCloseIco from "../../../../assets/icons/XCircleCloseIco";
import {
  CommonGrid2,
  DotColor,
  StyledFlexColumnView,
  StyledFlexRowView,
  SubTitleTypo,
  TitleTypo,
} from "../../../common-components/StyledComponents";
import { StyledSpaceBetweenBox } from "../../../portal/quick-match/components/StyledComponent";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useSelector } from "react-redux";
import CommonButton from "../../../../component/Button";
import SnackBar from "../../../../component/SnackBar";
import { JobJobShare } from "../../../../redux/action/Job";
import { ResumeIntelligenceSave } from "../../../../redux/action/ResumeIntelligence";
import { HandleSkeletion } from "../../../../utils/constants";
import ShareQuickMatch from "../../../portal/quick-match/components/ShareQuickMatch";
import DialogCandidateHub from "./DialogCandidateHub";
import ScheduleInterview from "./ScheduleInterview";
function Profile({
  isReviewCandidateProfile,
  matchedData,
  filteredResumeTab,
  resumeId,
  handleDownloadResume,
  handleClickAddNote,
  openRequest,
  handleChangeRequest,
  isCandidatePerspectiveOpen,
  loading,
}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElCompare, setAnchorElCompare] = React.useState(null);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const [openScheduleInterview, setScheduleInterview] = useState(false);
  const open = Boolean(anchorEl);
  const openCompare = Boolean(anchorElCompare);
  const [isLoading, setIsLoading] = useState(false);
  const [saveCandidateOpen, setSaveCandidateOpen] = useState(false);
  const [candidateProfileValue, setCandidateProfileValue] =
    useState("In Progress");
  const resumeIntelligence = useSelector(
    (state) => state.resumeIntelligence.resumeIntelligenceUpload
  );
  const location = useLocation();
  const query = new URLSearchParams(useLocation().search);
  const dataLoading = loading;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShareClick = () => {
    setShareDialogOpen(true);
  };

  const handleChangeScheduleInterview = () => {
    setScheduleInterview(true);
  };
  const handleCloseScheduleInterview = () => {
    setScheduleInterview(false);
  };

  const handleSaveToCandidatehub = () => {
    setAnchorEl(null);
    setSaveCandidateOpen(true);
  };
  const handleShareInvite = async (shareData) => {
    setIsLoading(true);
    try {
      let payload = {
        url: window.location.href,
        to_mail: shareData.map((item) => item.email),
        cc_mail: [],
      };
      const data = await JobJobShare(payload, "jobResume");
      setAnchorEl(null);
      setSnackbarState({
        open: true,
        message: data.message,
        severity: "success",
      });
      setShareDialogOpen(false);
      setIsLoading(false);
    } catch (error) {
      setAnchorEl(null);
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
      setIsLoading(false);
    }
  };

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleCloseCompare = () => {
    setAnchorElCompare(null);
  };
  const handleClickCompare = (event) => {
    setAnchorElCompare(event.currentTarget);
  };
  const statusOptions = [
    { label: "Hired", color: "#17B26A" }, // Green
    { label: "In Progress", color: "#9E77ED" }, // Purple
    { label: "Rejected", color: "#F04438" }, // Red
  ];

  const handleCompareNavigate = (item) => {
    navigate(`/jobs/candidate/compare/${resumeId}/${item?.id}`);
  };
  const menuItems = [
    {
      label: "Share",
      icon: <ShareIco />,
      onClick: () => {
        handleShareClick();
        handleClose();
      },
    },
    {
      label: "Compare",
      icon: <CalenderIco />,
      onClick: handleClickCompare,
      ariaControls: open ? "basic-menu" : undefined,
      ariaHasPopup: "true",
      ariaExpanded: open ? "true" : undefined,
    },
    {
      label: "Add Note",
      icon: <ClipBoardCheck />,
      onClick: () => {
        handleClickAddNote();
        handleClose();
      },
    },
    {
      label: "Download",
      icon: <Download fontSize="12px" />,
      onClick: handleDownloadResume,
    },
  ];

  const reviewCandidateProfileMenuItems = [
    {
      label: "Save to Candidate Hub",
      disabled: query.get("resume") === "parse" ? false : true,
      icon: <BookMarkIco />,
      onClick: handleSaveToCandidatehub,
    },
    {
      label: "Match to Active Jobs",
      icon: <BriefCaseIco />,
      disabled: true,
      onClick: handleClose,
    },
    {
      label: "Close",
      icon: <XCircleCloseIco />,
      onClick: handleClose,
    },
    {
      label: "Schedule Interview",
      disabled: true,
      icon: <ClipBoardCheck />,
      onClick: handleClose,
    },
    {
      label: "Compare",
      disabled: true,
      icon: <ScalesIco />,
      onClick: handleClose,
    },
  ];
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const handleShareDialogClose = () => {
    setShareDialogOpen(false);
  };
  const handleSaveCandidate = () => {
    setSaveCandidateOpen(false);
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const data = await ResumeIntelligenceSave(resumeIntelligence.data);
      setIsLoading(false);
      setSnackbarState({
        open: true,
        severity: "success",
        message: data.message,
      });
      setSaveCandidateOpen(false);
      navigate("/reusme-intelligence/create-candidate-resume");
    } catch (error) {
      setIsLoading(false);
      setSnackbarState({
        open: true,
        severity: "error",
        message: error?.data?.message || error.message,
      });
    }
  };

  const Footer = () => (
    <CommonGrid2 display={"flex"} gap={"1rem"}>
      <CommonButton
        value={"Save"}
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        disabled={isLoading}
        loading={isLoading}
        onClick={handleSave}
      />
    </CommonGrid2>
  );

  const gridData = [
    { title: "Title", contentKey: matchedData?.inferred_title || "-" },
    { title: "Rate", contentKey: matchedData?.pay_rate || "-" },
    {
      title: "Location",
      contentKey: matchedData?.location || "-",
      icon: <UsIco />,
    },
    {
      title: "Website",
      website: matchedData?.website || "-",
      isLink: true,
    },
  ];

  const reviewGridData = [
    { title: "Visa Status", contentKey: matchedData?.visa || "-" },
    { title: "Seniority", contentKey: matchedData?.seniority_level || "-" },
    {
      title: "Occupational Classification",
      contentKey: matchedData?.occupational_classification || "-",
    },
    { title: "Industry Experience", contentKey: matchedData?.industry || "-" },
  ];

  return (
    <Grid2 container columnSpacing={4} rowSpacing={dataLoading ? 2 : 4}>
      {!query.get("view-id") && (
        <Grid2 size={12}>
          <Divider />
        </Grid2>
      )}
      {!isCandidatePerspectiveOpen && (
        <Grid2 size={12}>
          <StyledSpaceBetweenBox>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Avatar
                alt={matchedData?.candidate_name || "Avatar"}
                src={matchedData?.avatar_url}
                sx={{
                  width: 64,
                  height: 64,
                  backgroundColor: "#F2F4F7",
                  color: "#667085",
                  border: "1px solid #D0D5DD",
                  fontFamily: "Inter",
                  fontWeight: 600,
                  fontSize: "24px",
                }}
              >
                {!matchedData?.avatar_url &&
                  matchedData?.candidate_name?.[0]?.toUpperCase()}
              </Avatar>
              <Box>
                <StyledFlexRowView>
                  <Typography fontSize={"24px"} fontWeight={600}>
                    {dataLoading ? (
                      <HandleSkeletion height={35} width={300} />
                    ) : (
                      matchedData?.candidate_name
                    )}
                  </Typography>
                  {isReviewCandidateProfile ? null : (
                    <FormControl>
                      <Select
                        value={candidateProfileValue}
                        onChange={(e) => {
                          setCandidateProfileValue(e.target.value);
                        }}
                        displayEmpty
                        renderValue={(selected) => {
                          const selectedOption = statusOptions.find(
                            (option) => option.label === selected
                          );
                          return (
                            <Box display={"flex"} alignItems={"center"}>
                              <DotColor
                                color={selectedOption.color}
                                marginRight="8px"
                              />
                              <Typography>{selectedOption.label}</Typography>
                            </Box>
                          );
                        }}
                        IconComponent={ExpandMore}
                        sx={{
                          "& .MuiSelect-select": {
                            paddingLeft: "10px !important",
                            paddingTop: "1px !important",
                            paddingBottom: "1px !important",
                            display: "flex",
                            alignItems: "center",
                            borderRadius: "6px",
                            paddingRight: "25px !important",
                          },
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#D0D5DD",
                            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                          },
                          "& .MuiSelect-icon": {
                            fontSize: "14px",
                          },
                        }}
                      >
                        {statusOptions.map((option) => (
                          <MenuItem key={option.label} value={option.label}>
                            <Box display={"flex"} alignItems={"center"}>
                              {option.label}
                            </Box>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </StyledFlexRowView>

                {dataLoading ? (
                  <HandleSkeletion height={35} width={200} />
                ) : (
                  <>
                    {matchedData?.email ? (
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Link
                          href={`mailto:${matchedData?.email}`}
                          underline="none"
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "#7F56D9",
                            fontSize: "16px",
                            fontFamily: "Inter",
                          }}
                        >
                          {matchedData?.email}
                        </Link>
                        <CopyIco />
                      </Stack>
                    ) : null}
                  </>
                )}
              </Box>
            </Stack>

            {dataLoading ? (
              <HandleSkeletion height={100} width={200} />
            ) : (
              <StyledFlexRowView gap={"1.5rem"}>
                {isReviewCandidateProfile ? null : (
                  <CommonButton
                    value={"Schedule Interview"}
                    padding={"10px 14px"}
                    variant="outlined"
                    size="small"
                    color="rgb(127, 86, 217)"
                    borderRadius="8px"
                    fontWeight={600}
                    border="rgb(127, 86, 217)"
                    onClick={handleChangeScheduleInterview}
                  >
                    {"Schedule Interview"}
                  </CommonButton>
                )}
                <CommonButton
                  value={"Request Info"}
                  padding={"10px 14px"}
                  variant="outlined"
                  color={"#344054"}
                  border={"#D0D5DD"}
                  fontWeight={"600"}
                  onClick={handleChangeRequest}
                />

                <div>
                  <CommonButton
                    value={<DotsVerticalIco />}
                    padding={"10px 14px"}
                    variant="outlined"
                    color={"#344054"}
                    border={"#D0D5DD"}
                    fontWeight={"600"}
                    aria-controls={open ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    minWidth={"20px !important"}
                  />

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {(isReviewCandidateProfile
                      ? reviewCandidateProfileMenuItems
                      : menuItems
                    ).map((item, index) => (
                      <MenuItem
                        key={index}
                        onClick={item.disabled ? () => { } : item.onClick}
                        aria-controls={item.ariaControls}
                        aria-haspopup={item.ariaHasPopup}
                        aria-expanded={item.ariaExpanded}
                        style={{ opacity: item.disabled ? 0.5 : 1 }}
                      >
                        <StyledFlexRowView>
                          {item.icon}
                          <Typography>{item.label}</Typography>
                        </StyledFlexRowView>
                      </MenuItem>
                    ))}
                  </Menu>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorElCompare}
                    open={openCompare}
                    onClose={handleCloseCompare}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {filteredResumeTab?.map((item, imdex) => (
                      <MenuItem
                        sx={{ marginTop: "10px" }}
                        onClick={() => handleCompareNavigate(item)}
                      >
                        <>
                          <ListItemIcon>
                            <Avatar
                              alt={"a"}
                              src="https://mui.com/static/images/avatar/1.jpg"
                              sx={{ width: 24, height: 24 }}
                            />
                          </ListItemIcon>
                          <ListItemText>
                            <Typography>{item?.name}</Typography>
                          </ListItemText>
                          <Chip
                            label={`${item?.score}%`}
                            variant="outlined"
                            size="small"
                            sx={{
                              paddingX: "5px",
                              paddingY: "1px",
                              marginLeft: "30px",
                            }}
                          />
                        </>
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </StyledFlexRowView>
            )}
          </StyledSpaceBetweenBox>
        </Grid2>
      )}
      {gridData.map((item) => (
        <Grid2 size={3} key={item.id}>
          {dataLoading ? (
            <HandleSkeletion height={50} />
          ) : (
            <StyledFlexColumnView gap={"6px"}>
              {item.title && <TitleTypo>{item.title}</TitleTypo>}

              {item.contentKey && (
                <SubTitleTypo>{item.contentKey}</SubTitleTypo>
              )}

              {item.isLink && (
                <SubTitleTypo fontSize={"15px"}>{item.website}</SubTitleTypo>
              )}
            </StyledFlexColumnView>
          )}
        </Grid2>
      ))}
      {isReviewCandidateProfile &&
        reviewGridData.map((item) => (
          <Grid2 size={3}>
            {dataLoading ? (
              <HandleSkeletion height={50} />
            ) : (
              <StyledFlexColumnView gap={"6px"}>
                <TitleTypo>{item.title}</TitleTypo>
                <SubTitleTypo>{item.contentKey}</SubTitleTypo>
              </StyledFlexColumnView>
            )}
          </Grid2>
        ))}
      {!isCandidatePerspectiveOpen && (
        <Grid2 size={12}>
          <Divider />
        </Grid2>
      )}
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
      <ShareQuickMatch
        shareDialogOpen={shareDialogOpen}
        handleShareDialogClose={handleShareDialogClose}
        handleShareInvite={handleShareInvite}
        isLoading={isLoading}
      />
      <ScheduleInterview
        open={openScheduleInterview}
        onClose={handleCloseScheduleInterview}
        onApply={() => {
          handleCloseScheduleInterview();
        }}
      />
      <DialogCandidateHub
        open={saveCandidateOpen}
        handleClose={handleSaveCandidate}
        footer={<Footer />}
        title="Save to Candidate Hub?"
        content={
          <>
            You are about to save a candidate under the name{" "}
            <strong style={{ fontWeight: 700 }}>
              {resumeIntelligence?.data?.personal_details?.candidate_name}
            </strong>{" "}
            and the role{" "}
            <strong style={{ fontWeight: 700 }}>
              {resumeIntelligence?.data?.personal_details?.inferred_title}
            </strong>{" "}
            in the Candidate Hub.
          </>
        }
      />
    </Grid2>
  );
}

export default Profile;
