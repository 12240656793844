import { TabContext } from "@mui/lab";
import { Box, Grid2, Tab, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import FileExtensionIco from "../../../assets/icons/FileExtensionIco";
import SnackBar from "../../../component/SnackBar";
import { ResumeIntelligenceUploadResume } from "../../../redux/action/ResumeIntelligence";
import {
  CommonGrid2,
  CreateTabList,
  TabPanelView,
} from "../../common-components/StyledComponents";
import ReviewCandidateProfile from "./components/ReviewCandidateProfile";
import UploadCandidateResume from "./components/UploadCandidateResume";
import { useLocation, useNavigate } from "react-router-dom";
import CommonButton from "../../../component/Button";
import DialogCandidateHub from "../../jobs/jobs-candidate/components/DialogCandidateHub";

function CreateCandidateResume() {
  const [value, setValue] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const dispatch = useDispatch();
  const allowedTypes = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  const location = useLocation();
  const resumeLoading = false;
  const [resumeData, setResumeData] = useState({});
  const navigate = useNavigate();
  const query = new URLSearchParams(useLocation().search);
  const [fixOpen, setFixOpen] = useState(false);
  const [tabData, setTabData] = useState([
    {
      name: "Upload Candidate Resume",
      disabled: false,
    },
    {
      name: "Review Candidate Profile",
      disabled: true,
    },
  ]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const uploadFile = async () => {
    setIsLoading(true);

    const formData = new FormData();

    files.forEach((fileObj, index) => {
      formData.append("files", fileObj.file);
    });

    try {
      const response = await ResumeIntelligenceUploadResume(formData);
      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let buffer = "";

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        buffer += chunk;
        let boundaryIndex;
        while ((boundaryIndex = buffer.indexOf("}\n")) !== -1) {
          const jsonString = buffer.slice(0, boundaryIndex + 1);
          buffer = buffer.slice(boundaryIndex + 1);
          const jsonResponse = JSON.parse(jsonString);
          try {
            processJsonResponse(jsonResponse);
          } catch (e) {
            setFiles((prevFiles) =>
              prevFiles.map((f) => ({
                ...f,
                visible: true,
                delete: true,
                message: jsonResponse.message,
              }))
            );

            setSnackbarState({
              open: true,
              message: "Error parsing JSON",
              severity: "error",
            });
          }
        }
      }

      if (buffer) {
        const jsonResponse = JSON.parse(buffer);
        try {
          processJsonResponse(jsonResponse);
        } catch (e) {
          setFiles((prevFiles) =>
            prevFiles.map((f) => ({
              ...f,
              visible: true,
              delete: true,
              message: jsonResponse.message,
            }))
          );

          setSnackbarState({
            open: true,
            message: "Error parsing final JSON chunk",
            severity: "error",
          });
        }
      }
      setIsLoading(false);
    } catch (error) {
      setFiles((prevFiles) =>
        prevFiles.map((f) => ({
          ...f,
          visible: true,
          delete: true,
          message: "Error during the request",
        }))
      );

      setSnackbarState({
        open: true,
        message: "Error during the request",
        severity: "error",
      });

      setIsLoading(false);
    }
  };

  const processJsonResponse = (jsonResponse) => {
    const { success, data, message } = jsonResponse;

    if (success && data?.percent !== undefined) {
      setFiles((prevFiles) =>
        prevFiles.map((f) => ({
          ...f,
          progress: data.percent,
          buffer: data.buffer || data.percent,
          visible: false,
          delete: false,
        }))
      );
    } else if (data?.id) {
      setSnackbarState({
        open: true,
        message: message,
        severity: "success",
      });

      setTabData((prev) =>
        prev.map((tab, index) => (index < 1 ? { ...tab, disabled: true } : tab))
      );
      setValue(1);
    } else if (!success) {
      setFiles((prevFiles) =>
        prevFiles.map((f) => ({
          ...f,
          visible: true,
          delete: true,
          message: message,
        }))
      );
      setSnackbarState({
        open: true,
        message: data.message,
        severity: "error",
      });
    }
  };

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const commonFileValidationCheck = (
    commonFiles,
    allowedTypes,
    setCommonFiles,
    existingFiles
  ) => {
    const validFiles = [];
    const invalidFiles = [];

    commonFiles.forEach((file) => {
      if (allowedTypes.includes(file.type)) {
        validFiles.push({
          file,
          name: file.name,
          size: `${(file.size / 1024).toFixed(1)} KB`,
          type: file.type,
          visible: false,
          delete: true,
          message: "",
        });
      } else {
        invalidFiles.push(file.name);
      }
    });

    const mergedFiles = [
      ...existingFiles,
      ...validFiles.filter(
        (newFile) =>
          !existingFiles.some(
            (existingFile) => existingFile.name === newFile.name
          )
      ),
    ];
    setCommonFiles(mergedFiles);

    if (invalidFiles.length > 0) {
      setSnackbarState({
        open: true,
        message: `Unsupported file types: ${invalidFiles.join(", ")}`,
        severity: "error",
      });
    }
  };

  const handleFileUpload = (event) => {
    const selectedFiles = Array.from(event.target.files);
    commonFileValidationCheck(selectedFiles, allowedTypes, setFiles, files);
  };

  // Handle file deletion
  const handleDeleteFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleNextUploadClick = async () => {
    if (files.length == 0) {
      setSnackbarState({
        open: true,
        message: "Upload the Files",
        severity: "error",
      });
    } else {
      setIsLoading(true);
      const formData = new FormData();

      files.forEach((fileObj, index) => {
        formData.append("files", fileObj.file);
      });
      try {
        setFiles((prevFiles) =>
          prevFiles.map((f) => ({
            ...f,
            delete: false,
          }))
        );
        const response = await dispatch(
          ResumeIntelligenceUploadResume(formData)
        );
        setResumeData(response.data);
        console.log(response);
        setTabData((prev) =>
          prev.map((tab, index) =>
            index < 1 ? { ...tab, disabled: true } : tab
          )
        );
        navigate("/reusme-intelligence/create-candidate-resume?resume=parse");
        setValue(1);
        setSnackbarState({
          open: true,
          message: response.message,
          severity: "success",
        });
        setIsLoading(false);
      } catch (error) {
        setFiles((prevFiles) =>
          prevFiles.map((f) => ({
            ...f,
            delete: true,
          }))
        );
        setSnackbarState({
          open: true,
          message: error.message,
          severity: "error",
        });
        setIsLoading(false);
      }
    }
  };

  // Drag-and-drop functionality
  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    const selectedFiles = Array.from(event.dataTransfer.files);
    commonFileValidationCheck(selectedFiles, allowedTypes, setFiles, files);
  };

  const getFileIcon = (file) => {
    const { name, type } = file;
    let extension = "";
    if (type.includes("pdf")) {
      extension = "PDF";
      return (
        <FileExtensionIco extension={extension} extensionColor={"#D92D20"} />
      );
    }
    if (type.includes("wordprocessingml.document") || name.endsWith(".docx")) {
      extension = "DOCX";
      return (
        <FileExtensionIco extension={extension} extensionColor={"#155EEF"} />
      );
    }
    if (type.includes("msword") || name.endsWith(".doc")) {
      extension = "DOC";
      return (
        <FileExtensionIco extension={extension} extensionColor={"#155EEF"} />
      );
    }
    return <FileExtensionIco extension={""} extensionColor={""} />;
  };

  useEffect(() => {
    navigate("/reusme-intelligence/create-candidate-resume");
  }, []);

  const Footer = () => (
    <CommonGrid2 display={"flex"} gap={"1rem"}>
      <CommonButton
        value={"Ok"}
        width={"100%"}
        padding={"10px"}
        marginTop={"2rem"}
        fontWeight={"700"}
        fontSize={"15px"}
        onClick={handleFixClose}
      />
    </CommonGrid2>
  );
  const handleFixClose = () => {
    setFixOpen(false);
  };

  const handleScoreFix = () => {
    if (query.get("resume") === "parse") {
      setFixOpen(true);
    }
  };

  return (
    <Grid2 container spacing={3}>
      <Grid2 size={12}>
        <Typography fontSize={"24px"} fontWeight={600}>
          {"Resume Intelligence Engine Module"}
        </Typography>
      </Grid2>
      <Grid2 size={12}>
        <Box width={"100%"}>
          <TabContext value={value}>
            <Grid2 alignItems="center" marginTop={3}>
              <CreateTabList onChange={handleChange} aria-label="create-job">
                {tabData.map((item, index) => (
                  <Tab
                    label={item.name}
                    value={index}
                    disabled={item.disabled}
                    fontSize={"20px"}
                  />
                ))}
              </CreateTabList>
            </Grid2>
            <TabPanelView value={0} padding="1.5rem 0px 0px">
              <UploadCandidateResume
                handleDragOver={handleDragOver}
                handleDragLeave={handleDragLeave}
                handleDrop={handleDrop}
                handleFileUpload={handleFileUpload}
                isDragging={isDragging}
                files={files}
                getFileIcon={getFileIcon}
                handleDeleteFile={handleDeleteFile}
                handleNextUploadClick={handleNextUploadClick}
                isLoading={isLoading}
              />
            </TabPanelView>
            <TabPanelView value={1} padding="1.5rem 0px 0px">
              <ReviewCandidateProfile
                resumeData={resumeData}
                isLoading={resumeLoading}
                handleScoreFix={handleScoreFix}
              />
            </TabPanelView>
          </TabContext>
        </Box>
      </Grid2>
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
      <DialogCandidateHub
        open={fixOpen}
        handleClose={handleFixClose}
        footer={<Footer />}
        title="Save to Candidate Hub?"
        content={
          <>
            Save the Candidate Hub to access the{" "}
            <strong style={{ fontWeight: 700 }}>Fix</strong> feature for
            correcting grammar mistakes
          </>
        }
      />
    </Grid2>
  );
}

export default CreateCandidateResume;
