import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import DotsGrid from "../../../../assets/icons/DotsGrid";
import CustomLinearProgress from "../../../../component/Linearprogress";
import {
  ErrorFound,
  getColor,
  HandleSkeletion,
} from "../../../../utils/constants";
import {
  DotColor,
  StatusChange,
  StyledFlexRowView,
  StyledTableBodyTableCell,
  StyledTableTitleTableCell,
  StyledTypoGrey,
  TableContainerBorder,
} from "../../../common-components/StyledComponents";

function Results({ SummaryJobData, tableLoader, resultHeaderData }) {
  console.log(SummaryJobData, "resultHeaderDataresultHeaderData")
  const [status, setStatus] = useState("Hired");

  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  const statusOptions = [
    { label: "Hired", color: "#17B26A" }, // Green
    { label: "In Progress", color: "#9E77ED" }, // Purple
    { label: "Rejected", color: "#F04438" }, // Red
  ];

  const [value, setVlaue] = useState("kathirvel");
  const handleChanges = (e) => {
    setVlaue(e.target.value);
  };

  return (
    <TableContainerBorder marginTop={"0px"}>
      <Table>
        <TableHead>
          <TableRow>
            {resultHeaderData.map((item, index) => (
              <StyledTableTitleTableCell>{item}</StyledTableTitleTableCell>
            ))}
          </TableRow>
        </TableHead>
        {tableLoader && (
          <>
            {Array.from({ length: 2 }).map((_, index) => (
              <TableRow>
                {Array.from({ length: 3 }).map((_, index) => (
                  <TableCell key={index}>
                    <HandleSkeletion height={30} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </>
        )}
        {SummaryJobData &&
          SummaryJobData?.results?.candidates?.length !== 0 && (
            <TableBody>
              {SummaryJobData.results.candidates.map((candidate, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {tableLoader ? (
                      <HandleSkeletion height={30} />
                    ) : (
                      <Typography
                        fontSize={"15px"}
                        fontWeight={500}
                        color={"#101828"}
                        fontFamily={"Inter"}
                      >
                        {candidate.candidate_name}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>
                    {tableLoader ? (
                      <HandleSkeletion height={30} />
                    ) : (
                      <StatusChange>
                        <DotColor color={"#7F56D9"} />
                        {candidate.status}
                      </StatusChange>
                    )}
                    {/* <DropDownSelect
                  InputPadding={"2px"}
                  borderRadius={"5px"}
                  value={value}
                  sizeMedium={"-14px"}
                  data={[
                    {
                      value: "kathirvel",
                      id: 1,
                    },
                  ]}
                  handleChange={handleChanges}
                /> */}
                    {/* <FormControl>
                  <Select
                    value={candidate?.status}
                    onChange={handleChange}
                    displayEmpty
                    renderValue={(selected) => {
                      const selectedOption = statusOptions.find(
                        (option) => option.label === selected
                      );
                      return (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box
                            sx={{
                              width: 8,
                              height: 8,
                              borderRadius: "50%",
                              backgroundColor: selectedOption.color,
                              marginRight: "8px",
                            }}
                          />
                          <Typography>{selectedOption.label}</Typography>
                        </Box>
                      );
                    }}
                    IconComponent={ExpandMore}
                    sx={{
                      "& .MuiSelect-select": {
                        paddingLeft: "10px !important",
                        paddingTop: "1px !important",
                        paddingBottom: "1px !important",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "6px",
                        paddingRight: "25px !important",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#D0D5DD",
                        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                      },
                      "& .MuiSelect-icon": {
                        fontSize: "14px",
                      },
                    }}
                  >
                    {statusOptions.map((option) => (
                      <MenuItem key={option.label} value={option.label}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                  </TableCell>
                  <TableCell>
                    {tableLoader ? (
                      <HandleSkeletion height={30} />
                    ) : (
                      <StyledFlexRowView sx={{ alignItems: "center" }}>
                        <CustomLinearProgress
                          variant="determinate"
                          value={candidate.score}
                          height={"10px"}
                          borderRadius={"5px"}
                          barbackgroundColor={getColor(candidate.score)}
                        />

                        <StyledTypoGrey>{`${candidate.score}%`}</StyledTypoGrey>
                      </StyledFlexRowView>
                    )}
                  </TableCell>
                  <StyledTableBodyTableCell textAlign={"center"}>
                    {tableLoader ? (
                      <HandleSkeletion height={30} />
                    ) : (
                      <DotsGrid />
                    )}
                  </StyledTableBodyTableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
      </Table>
      {SummaryJobData?.results?.candidates?.length === 0 && !tableLoader && (
        <ErrorFound title={"No data Found..."} />
      )}
    </TableContainerBorder>
  );
}

export default Results;
