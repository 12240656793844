import { styled, TableCell } from "@mui/material";
import Tippy from "@tippyjs/react";

export const PrimaryDiv = styled("div")`
  border: 1px solid #eaecf0;
  border-radius: 10px;
  padding: 12px 15px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  height: 100%;
`;

export const SecondaryShift = styled("div")`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
`;

export const CustomizeTableCell = styled(TableCell)(
  ({
    fontWeight,
    display,
    justifyContent,
    gap,
    alignItems,
    textAlign,
    verticalAlign,
    width,
  }) => ({
    fontWeight: `${fontWeight} !important`,
    display: display,
    justifyContent: justifyContent,
    gap: gap,
    alignItems: alignItems,
    textAlign: textAlign,
    width: width,
    verticalAlign: verticalAlign,
  })
);

export const SkillCriticality = styled("div")`
  width: 100%;
  gap: 10px;
  display: flex;
  align-items: center;
`;

export const SelectCreateCard = styled("div")`
  border: 1px solid #eaecf0;
  border-radius: 15px;
  padding: 1.2rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`;

export const CustomTooltipField = styled("div")`
  position: absolute;
  top: -40px;
  left: 50%;
  transform: translateX(-50%);
  background-color: red;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  border-radius: 4px;
  white-space: nowrap;
  z-index: 1000;
  &::before {
    content: "";
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid red;
  }
`;

export const CommonTippy = styled(Tippy)(({ color, fontWeight, fontSize }) => ({
  backgroundColor: `${color} !important`,
  fontWeight: fontWeight,
  fontSize: `${fontSize} !important`,
  "& .tippy-arrow": {
    color: `${color}`,
  },
}));
