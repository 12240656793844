import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import React from "react";
import { StyledFlexRowView } from "../../../common-components/StyledComponents";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Tooltip,
  Legend,
} from "recharts";

function Comparison({ matchedData }) {
  const data = [
    { category: "Category 1", A: 10, B: 38, C: 58, fullMark: 100 },
    { category: "Category 2", A: 98, B: 88, C: 48, fullMark: 100 },
    { category: "Category 3", A: 86, B: 28, C: 28, fullMark: 100 },
    { category: "Category 4", A: 99, B: 68, C: 48, fullMark: 100 },
    { category: "Category 5", A: 85, B: 48, C: 18, fullMark: 100 },
  ];

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          sx={{
            bgcolor: "black",
            color: "white",
            p: 2,
            borderRadius: 1,
            width: "320px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "12px",
              color: "#FFFFFF",
            }}
          >
            {payload[0].name}
          </Typography>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "12px",
              color: "#FFFFFF",
            }}
          >
            Zahir excels at Hard Skills and he’s on average 25% better than
            other candidates.
          </Typography>
        </Box>
      );
    }
    return null;
  };

  // const CustomLegend = () => {
  //   const legendData = [
  //     { name: "Zahid Mays", color: "#6941C6" },
  //     { name: "Phoenix Baker", color: "#667085" },
  //     { name: "Freya Browing", color: "#667085" },
  //   ];

  //   return (
  //     <Box
  //       sx={{
  //         display: "flex",
  //         justifyContent: "center",
  //         gap: 2,
  //         mt: 2,
  //       }}
  //     >
  //       {legendData.map((item, index) => (
  //         <Box
  //           key={index}
  //           sx={{
  //             display: "flex",
  //             alignItems: "center",
  //             gap: 1,
  //             fontSize: "14px",
  //             fontWeight: "500",
  //             color: "#101828",
  //           }}
  //         >
  //           <Box
  //             sx={{
  //               width: 8,
  //               height: 8,
  //               backgroundColor: item.color,
  //               borderRadius: "50%",
  //             }}
  //           />
  //           <Typography
  //             sx={{
  //               fontSize: "14px",
  //               fontWeight: 400,
  //               color: "#475467",
  //             }}
  //           >
  //             {item.name}
  //           </Typography>
  //         </Box>
  //       ))}
  //     </Box>
  //   );
  // };

  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        {payload.map((entry, index) => (
          <div
            key={`item-${index}`}
            style={{ display: "flex", alignItems: "center", margin: "0 10px" }}
          >
            <Box
              sx={{
                width: 8,
                height: 8,
                backgroundColor: entry.color,
                borderRadius: "50%",
                marginRight: "5px",
              }}
            />
            {/* <div
              style={{
                width: "8px",
                height: "8px",
                backgroundColor: entry.color,
                marginRight: "5px",
              }}
            /> */}
            {/* <span style={{ fontWeight: "bold", color: entry.color }}>
              {entry.value}
            </span> */}
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 400,
                color: "#475467",
              }}
            >
              {entry.value}
            </Typography>
          </div>
        ))}
      </div>
    );
  };

  const CustomPolarRadiusAxisTick = (props) => {
    const { x, y, payload } = props;
    return (
      <text
        x={x}
        y={y}
        dy={16}
        textAnchor="middle"
        fill="#666"
        fontWeight="bold"
      >
        {payload.value}
      </text>
    );
  };

  return (
    <Accordion
      defaultExpanded
      sx={{
        boxShadow: "none",
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          "& .MuiAccordionSummary-expandIconWrapper": {
            border: "1px solid #D0D5DD",
            borderRadius: "8px",
            padding: "4px",
          },
          "& .MuiAccordionSummary-content.Mui-expanded": {
            margin: "0px !important",
            minHeight: "55px",
          },
          padding: "0px !important",
        }}
      >
        <StyledFlexRowView>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: 600,
              color: "#101828",
              display: "flex",
              alignItems: "center",
            }}
          >
            Comparison
          </Typography>
        </StyledFlexRowView>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0px" }}>
        <Box
          sx={{
            width: "100%",
            mx: "auto",
            boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
            borderRadius: "12px",
            border: "1px solid #EAECF0 !important",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            py: 1,
          }}
        >
          <RadarChart
            cx="50%"
            cy="50%"
            outerRadius="90%"
            width={550}
            height={400}
            // data={data}
            data={matchedData?.spider_graph_results}
          >
            <PolarGrid />

            <PolarAngleAxis dataKey="name" />
            <PolarRadiusAxis
              angle={30}
              domain={[0, 100]}
              tick={CustomPolarRadiusAxisTick}
              axisLine={false}
            />
            <Radar
              name="Candidate Score"
              dataKey="candidate_score"
              stroke="#6941C6"
              fill="#7F56D9"
              fillOpacity={0.3}
            />

            <Radar
              name="Highest Score"
              dataKey="highest_score"
              stroke="#667085a1"
              fill="#EAECF0"
              fillOpacity={0.3}
            />

            <Radar
              name="Average Score"
              dataKey="average_score"
              stroke="#F79009"
              fill="#F79009"
              fillOpacity={0.1}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend content={renderLegend} />
          </RadarChart>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

export default Comparison;
