import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  AccordionDetails,
  AccordionSummary,
  Grid2,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CommonButton from "../../../component/Button";
import SnackBar from "../../../component/SnackBar";
import { JobJobShare, JobSummary } from "../../../redux/action/Job";
import {
  CustomAccordion,
  StyledFlexRowView,
} from "../../common-components/StyledComponents";
import Interactions from "./components/Interactions";
import Results from "./components/Results";
import Skills from "./components/Skills";
import SummaryInteractionDialog from "./components/SummaryInteractionDialog";
import ShareQuickMatch from "../../portal/quick-match/components/ShareQuickMatch";

function JobsSummary() {
  const SummaryJobData = useSelector((state) => state.jobs.jobSummary);
  const query = new URLSearchParams(useLocation().search);
  const jobId = query.get("id");
  const dispatch = useDispatch();
  const [tableLoader, setTableLoader] = useState(false);
  const resultHeaderData = ["Candidate", "Status", "Score", ""];
  const attributeHeaderData = ["Skill", "Experience", "Median Value"];
  const [interActionOpen, setInterActionOpen] = useState(false);
  const [interActionData, setInterActionData] = useState({});
  const interactionHeaderData = [
    "ID",
    "Type",
    "Action",
    "Result",
    "Date",
    "Made By",
    "",
  ];
  const [isLoading, setIsLoading] = useState(false);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);

  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: "",
    severity: "error",
  });

  const fetchSummaryDetails = async () => {
    try {
      setTableLoader(true);
      await dispatch(JobSummary(jobId));
      setTableLoader(false);
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.data.message || error.message,
        severity: "error",
      });
      setTableLoader(false);
    }
  };

  useEffect(() => {
    fetchSummaryDetails();
  }, [jobId]);

  const handleCloseValidation = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
  };

  const handleInteractionAction = (data) => {
    setInterActionOpen(true);
    setInterActionData(data);
    console.log(data);
  };
  const accordionDataView = [
    {
      title: (
        <StyledFlexRowView>
          Results
          <Typography fontSize={"20px"} fontWeight={600} color={"#667085"}>
            · {SummaryJobData?.results?.count}
          </Typography>
        </StyledFlexRowView>
      ),
      component: (
        <Results
          SummaryJobData={SummaryJobData}
          tableLoader={tableLoader}
          resultHeaderData={resultHeaderData}
        />
      ),
    },
    {
      title: "Skills",
      component: (
        <Skills
          attributeHeaderData={attributeHeaderData}
          SummaryJobData={SummaryJobData}
          tableLoader={tableLoader}
        />
      ),
    },
    {
      title: "Interactions",
      component: (
        <Interactions
          SummaryJobData={SummaryJobData}
          interactionHeaderData={interactionHeaderData}
          tableLoader={tableLoader}
          handleInteractionAction={handleInteractionAction}
        />
      ),
    },
  ];

  const handleShareDialogClose = () => {
    setShareDialogOpen(false);
  };

  const handleShareInvite = async (shareData) => {
    setIsLoading(true);
    try {
      let payload = {
        url: window.location.href,
        to_mail: shareData.map((item) => item.email),
        cc_mail: [],
      };
      const data = await JobJobShare(payload, "summary");
      setSnackbarState({
        open: true,
        message: data.message,
        severity: "success",
      });
      handleShareDialogClose();
      setIsLoading(false);
    } catch (error) {
      setSnackbarState({
        open: true,
        message: error.message,
        severity: "error",
      });
      setIsLoading(false);
    }
  };

  const handleShare = () => {
    setShareDialogOpen(true);
  };

  return (
    <Grid2 container spacing={3}>
      <Grid2 size={12}>
        {accordionDataView.map((item, index) => (
          <CustomAccordion
            ContentMargin={"0px !important"}
            paddingBottom={"1rem"}
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<KeyboardArrowUpIcon />}
              aria-controls={`panel${index}-content`}
              id={`panel${index}-header`}
            >
              <Typography variant="h4" margin={"15px 0px"} fontWeight={600}>
                {item.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid2 size={12}>{item.component}</Grid2>
            </AccordionDetails>
          </CustomAccordion>
        ))}
      </Grid2>

      <Grid2 size={12}>
        <CommonButton
          variant="outlined"
          padding={"6px 0"}
          color="#344054"
          border="#D0D5DD"
          fontWeight="600"
          value="Share"
          onClick={handleShare}
        />
      </Grid2>
      <SummaryInteractionDialog
        open={interActionOpen}
        handleClose={() => setInterActionOpen(false)}
        interActionData={interActionData}
      />
      <SnackBar
        snackbarState={snackbarState}
        handleCloseValidation={handleCloseValidation}
      />
      <ShareQuickMatch
        shareDialogOpen={shareDialogOpen}
        handleShareDialogClose={handleShareDialogClose}
        handleShareInvite={handleShareInvite}
        isLoading={isLoading}
      />
    </Grid2>
  );
}

export default JobsSummary;
