export const URL = process.env.REACT_APP_API_URL;

export const getJobDataDetail = (jobId) => `${URL}/intake/jobdata/${jobId}`;
export const GetJobList = (limit, offset) =>
  `${URL}/intake/job-list?limit=${limit}&offset=${offset}`;
export const getMatchedJdResumeData = (resumeId) =>
  `${URL}/matchengine/match/resume/jd/${resumeId}`;
export const getCompareCandidates = (id1, id2) =>
  `${URL}/matchengine/compare-resume/${id1}/${id2}`;
export const getMatchedJdlistResumeData = (id1, id2) =>
  `${URL}/matchengine/list-resume/${id1}/${id2}`;
export const getJdFileList = (jobId) => `${URL}/intake/jobdata/files/${jobId}`;
export const DownloadJd = (id) => `${URL}/intake/jobdata/download/doc/${id}`;
export const DownloadResume = (id) => `${URL}/resume/download/doc/${id}`;
export const uploadCandidateResume = () => `${URL}/matchengine/match/resume/jd`;

//JD Notes
export const addJdNotes = (jdId) => `${URL}/intake/add-note-jd/${jdId}/`;
export const getJdNotes = (jdId) => `${URL}/intake/get-notes-jd/${jdId}/`;
export const updateJdNote = (noteId) => `${URL}/intake/notes/${noteId}`;
export const deleteJdNote = (noteId) => `${URL}/intake/notes/${noteId}`;

//DAME Notes
export const addDameNotes = (resumeId) =>
  `${URL}/matchengine/match/add-note/${resumeId}/`;
export const getDameNotes = (resumeId) =>
  `${URL}/matchengine/match/get-notes/${resumeId}/`;
export const updateDameNote = (noteId) => `${URL}/matchengine/notes/${noteId}`;
export const deleteDameNote = (noteId) => `${URL}/matchengine/notes/${noteId}`;

export const SearchAndListOfResumes = (limit, offset) =>
  `${URL}/resume/search/filter?limit=${limit}&offset=${offset}`;

export const fixedJob = (job_id) => `${URL}/intake/error/fix/${job_id}`;
