import { URL } from "../../api/Api";
import { RESUMEINTELLIGENCE } from "../Action";
import { catchError, POST } from "../axios/Axios";

export const ResumeIntelligenceUploadResume = (formData) => {
  return async (dispatch) => {
    try {
      const response = await POST(`${URL}/resume/parse/`, formData, {});
      dispatch({
        type: RESUMEINTELLIGENCE.resumeIntelligenceUpload,
        data: response.data,
      });
      return response.data;
    } catch (error) {
      throw catchError(error);
    }
  };
};

export const ResumeIntelligenceSave = async (payload) => {
  try {
    const response = await POST(`${URL}/resume/save/`, payload, {});
    return response.data;
  } catch (error) {
    throw catchError(error);
  }
};
