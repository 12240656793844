import React from "react";
import { Button, CircularProgress, styled } from "@mui/material";

const ButtonFilter = styled(Button)(
  ({
    border,
    fontWeight,
    background,
    color,
    padding,
    borderRadius,
    height,
    marginTop,
    boxShadow,
    width,
    variant,
    margin,
    minWidth,
    gap,
  }) => ({
    padding: padding || "5px 28px",
    fontWeight: fontWeight || 500,
    border: border && `1px solid ${border}`,
    background: background,
    color: color,
    borderRadius: borderRadius || "8px",
    height: height,
    margin: margin,
    marginTop: marginTop,
    boxShadow: boxShadow,
    width: width,
    gap: gap || "10px",
    "&:hover": {
      background: variant === "outlined" ? "rgb(127, 86, 217)" : background,
      color: variant === "outlined" && "#fff",
    },
    minWidth: minWidth,
  })
);

const CommonButton = ({
  value,
  variant = "contained",
  color = "primary",
  onClick,
  startIcon,
  endIcon,
  fontWeight,
  border,
  background,
  padding,
  marginTop,
  disabled = false,
  boxShadow,
  width,
  margin,
  type = "button",
  loading,
  borderRadius,
  minWidth,
  gap,
}) => {
  return (
    <ButtonFilter
      variant={variant}
      color={color}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      fontWeight={fontWeight}
      border={border}
      background={background}
      padding={padding}
      marginTop={marginTop}
      disabled={disabled}
      boxShadow={boxShadow}
      width={width}
      type={type}
      margin={margin}
      borderRadius={borderRadius}
      minWidth={minWidth}
      gap={gap}
    >
      {disabled && loading && (
        <CircularProgress size={24} color="inherit" thickness={5} />
      )}
      {value}
    </ButtonFilter>
  );
};

export default CommonButton;
