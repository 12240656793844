export const QUICKMATCH = {
  reviewMatch: "reviewMatch",
};

export const JOBS = {
  jobs: "jobs",
  jobSummary: "jobSummary",
  jobJobEdit: "jobJobEdit",
};

export const RESUMEINTELLIGENCE = {
  resumeIntelligenceUpload: "resumeIntelligenceUpload",
};

export const CANDIDATE = {
  candidateView: "candidateView",
};
