import { Link, styled } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs as MUIBreadcrumbs, Link as MUILink } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Padding } from "@mui/icons-material";

const BreadcrumbsContainer = styled(MUIBreadcrumbs)(() => ({
  "& .MuiBreadcrumbs-li:last-child": {
    background: "#F9FAFB",
    padding: "5px 10px",
    borderRadius: "5px",
    color: "#344054",
    fontWeight: "bold",
    boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
  },
  "& .MuiBreadcrumbs-li a": {
    color: "#344054",
    fontSize: "14px",
    fontWeight: 600,
  },
}));

const Breadcrumbs = (props) => {
  const { breadCrumbData } = props;
  const navigate = useNavigate();
  return (
    <BreadcrumbsContainer
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {breadCrumbData?.map((item, index) => {
        const isLastItem = index === breadCrumbData.length - 1;
        return isLastItem ? (
          <span key={index} className={item.active === true ? "active" : ""}>
            {item.title}
          </span>
        ) : (
          <Link
            key={index}
            underline="hover"
            color="inherit"
            href={item.navigate ? null : item.href}
            onClick={item.navigate ? () => navigate(item.navigate) : null}
          >
            {item.title}
          </Link>
        );
      })}
    </BreadcrumbsContainer>
  );
};

export default Breadcrumbs;
