import { TabList, TabPanel } from "@mui/lab";
import {
  Accordion,
  Checkbox,
  Dialog,
  Grid2,
  LinearProgress,
  Table,
  TableCell,
  TableContainer,
  Tooltip,
  Typography,
} from "@mui/material";

import { styled } from "@mui/material/styles";

export const StyledFlexRowView = styled("div")(({ alignItems, gap }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: alignItems || "center",
  gap: gap || "10px",
}));

export const StyledFlexColumnView = styled("div")(({ gap }) => ({
  display: "flex",
  flexDirection: "column",
  //   alignItems: "center",
  gap: gap || "10px",
}));

export const StyledCheckBox = styled(Checkbox)(() => ({
  padding: "0px !important",
  color: "#DF1278",
  "&.Mui-checked": {
    color: "#DF1278",
  },
}));

export const StatusChange = styled("div")(
  ({ color, borderRadius, background, border, padding }) => ({
    border: border || "1px solid #D0D5DD",
    color: color || "#344054",
    width: "max-content",
    display: "flex",
    alignItems: "center",
    borderRadius: borderRadius || "5px",
    padding: padding || "2px 5px",
    gap: "5px",
    boxShadow: "0px 0px 1px 0px #101828",
    fontSize: "13px",
    background: background,
  })
);

export const ScoreChange = styled("div")(() => ({
  border: "1px solid #ABEFC6",
  color: "#067647",
  width: "max-content",
  display: "flex",
  alignItems: "center",
  borderRadius: "10px",
  padding: "2px 5px",
  gap: "5px",
  background: "#ECFDF3",
  fontSize: "13px",
}));

export const DotColor = styled("div")(({ color, marginRight }) => ({
  background: color,
  width: "7px",
  height: "7px",
  borderRadius: "50%",
  marginRight: marginRight,
}));

export const TableContainerBorder = styled(TableContainer)(
  ({ marginTop, fontSize, scrollStyle, height }) => ({
    border: "1px solid #EAECF0",
    borderRadius: "10px",
    marginTop: marginTop || "2.5rem",
    boxShadow: "0px 1px 2px 2px rgba(16, 24, 40, 0.05)",
    // boxShadow: " 0px 1px 2px 0px #1018280D",
    "& .MuiTableHead-root": {
      "& .MuiTableCell-root": {
        fontSize: fontSize,
      },
    },
    ...(scrollStyle && {
      height: height >= 6 ? "400px" : "auto",
      display: "block",

      "&::-webkit-scrollbar": {
        display: "block",
      },
      "&::-webkit-scrollbar": {
        width: "7px",
        height: "7px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },

      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "10px",
      },
    }),

    "& .MuiTableCell-body,.MuiTableCell-head": {
      fontWeight: 500,
      fontFamily: "Inter",
    },
    "& .MuiTableBody-root": {
      "& .MuiTableRow-root": {
        "&:nth-of-type(odd)": {
          backgroundColor: "#F9FAFB",
        },
      },
    },
    "& .MuiPagination-ul": {
      "& .MuiPaginationItem-text": {
        fontWeight: "500",
        fontFamily: "Inter",
      },
      "& .Mui-selected": {
        fontWeight: "600",
        fontFamily: "Inter",
      },
    },
  })
);

export const TabViewSection = styled(TabList)(() => ({
  "& .MuiTabs-flexContainer": {
    background: "#F9FAFB",
    border: "1px solid #EAECF0",
    borderRadius: "10px",
    width: "max-content",
  },
  "& .Mui-selected": {
    background: "#fff",
    margin: "8px",
    color: "#344054 !important",
    boxShadow: "0px 0px 1px 0px #101828",
    borderRadius: "5px",
  },
  "& .MuiTab-root": {
    padding: "0px 2.5rem",
    minHeight: "32px",
    color: "#667085",
    fontWeight: 600,
    fontFamily: "Inter",
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
}));

export const CreateTabList = styled(TabList)(() => ({
  "& .MuiTab-root": {
    borderTop: "4px solid #EAECF0",
    fontWeight: 600,
    fontFamily: "Inter",
    flexGrow: 1,
    color: "#344054",
    "&:hover": {
      color: "#6941C6",
      fontWeight: "bold",
      borderTop: "4px solid #7F56D9",
    },
  },
  "& .Mui-disabled": {
    color: "rgba(0, 0, 0, 0.38)",
  },
  "& .MuiButtonBase-root": {
    fontSize: "16px",
    maxWidth: "none",
    display: "flex",
    alignItems: "start",
    paddingLeft: "0px",
  },
  "& .MuiTabs-flexContainer": {
    justifyContent: "space-between",
    width: "100%",
    gap: "1rem",
    "& .Mui-selected": {
      color: "#6941C6",
      fontWeight: "bold",
      borderTop: "4px solid #7F56D9",
    },
  },
  "& .MuiTabs-indicator": {
    top: 0,
    height: 3,
  },
}));
export const StyledTableTitleTableCell = styled(TableCell)(({ textAlign }) => ({
  fontSize: "16px",
  fontWeight: 500,
  color: "#475467",
  fontFamily: "Inter",
  textAlign: textAlign,
}));

export const StyledTableBodyTableCell = styled(TableCell)(({ textAlign }) => ({
  fontSize: "16px",
  fontWeight: 500,
  color: "#101828",
  fontFamily: "Inter",
  textAlign: textAlign,
}));

export const StyledTypo = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: 500,
  color: "#101828",
  fontFamily: "Inter",
}));

export const StyledTypoGrey = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: 500,
  color: "#475467",
  fontFamily: "Inter",
}));

export const TabPanelView = styled(TabPanel)(({ padding }) => ({
  padding: padding,
}));

export const CommonGrid2 = styled(Grid2)(
  ({ gap, alignItems, display, justifyContent, flexDirection, overflowY }) => ({
    gap: gap,
    alignItems: alignItems,
    display: display,
    justifyContent: justifyContent,
    flexDirection: flexDirection,
    overflowY: overflowY,
  })
);

export const CustomAccordion = styled(Accordion)(
  ({ borderBottom, ContentMargin, paddingBottom }) => ({
    border: "0px",
    marginTop: "0.5rem",
    paddingBottom: paddingBottom || "0.5rem",
    boxShadow: "none",
    borderBottom: borderBottom,
    "&::before": {
      display: "none",
    },
    "& .MuiAccordionSummary-root": {
      padding: "0px",
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      border: "1px solid #D0D5DD",
      padding: "5px",
      borderRadius: "5px",
    },
    "& .MuiAccordionSummary-content": {
      margin: ContentMargin || "12px 0px",
    },
    "& .MuiAccordionDetails-root": {
      padding: "8px 0px 16px",
    },
  })
);

export const CustomLinearProgress = styled(LinearProgress)(({ color }) => ({
  flex: 1,
  height: "8px",
  borderRadius: "4px",
  backgroundColor: "#E0E0E0",
  "& .MuiLinearProgress-bar": {
    backgroundColor: color,
  },
}));

export const CustomTooltip = styled(({ className, color, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} arrow />
))(({ color }) => ({
  [`& .MuiTooltip-tooltip`]: {
    color: "#fff",
    backgroundColor: color || "#e9d2c9",

    padding: "7px 15px",
  },
  [`& .MuiTooltip-arrow`]: {
    "&::before": {
      backgroundColor: color || "#e9d2c9",
    },
  },
}));

export const TitleTypo = styled(Typography)(() => ({
  fontSize: "16px",
  fontWeight: 600,
  color: "#475467",
}));

export const SubTitleTypo = styled(Typography)(({ fontSize }) => ({
  fontSize: fontSize || "16px",
  fontWeight: 600,
  color: "#101828",
}));
export const CheckBoxSelected = styled("div")(() => ({
  // padding: "32px 0px 0px 0px",
  fontSize: "20px",
  fontFamily: "Inter",
  fontWeight: 600,
}));
export const StyledDialog = styled(Dialog)(() => ({
  borderRadius: "10px",
}));

export const DataFound = styled("div")`
  text-align: center;
  background: rgb(105 65 198 / 13%);
  border: 1px solid rgb(105, 65, 198);
  width: max-content;
  margin: 1.5rem auto;
  font-family: system-ui;
  padding: 0px 10px;
  border-radius: 5px;
  color: rgb(105, 65, 198);
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
  flex-direction: row;
`;

export const DialogTableList = styled("div")(
  ({ display, gap, flexWrap, border, padding, borderRadius }) => ({
    display: display,
    gap: gap,
    flexWrap: flexWrap,
    border: border,
    borderRadius: borderRadius,
    padding: padding,
  })
);

export const CustomTable = styled(Table)(() => ({
  borderCollapse: "separate",
  borderSpacing: "10px",
  "& .MuiTableCell-root": {
    border: "1px solid #EAECF0",
    borderRadius: "5px",
    boxShadow: "0px 1px 1px 1px rgba(16, 24, 40, 0.05)",
    padding: "10px",
  },
}));
