import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import FilterBackGroundImage from "../../assets/images/FilterBackGround.png";
import CommonButton from "../../component/Button";
import DateRangePickerComp from "../jobs/common-card.js/DatePicker";
import { StyledDialog, StyledFlexRowView } from "./StyledComponents";

const FilterDialog = ({ open, onClose, title = "Filter", filterOptions = [], onApply, clearForm, count }) => {
  const [filterState, setFilterState] = useState(
    filterOptions.reduce((acc, option) => {
      acc[option.key] = option.type === "multi-select" ? [] : option.value || null;
      return acc;
    }, {})
  );

  const handleDropdownChange = (key, value) => {
    setFilterState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleDateChange = (key, start, end) => {
    setFilterState((prevState) => {
      const newState = {
        ...prevState,
        [key]: {
          from_date: start,
          to_date: end || start,
        },
      };

      return newState;
    });
  };




  return (
    <StyledDialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <img
        src={FilterBackGroundImage}
        style={{ position: "absolute", width: "max-content", opacity: "0.7" }}
        alt="Filter Background"
      />
      <DialogActions>
        <Button onClick={onClose}>
          <CloseIcon />
        </Button>
      </DialogActions>
      <DialogTitle>
        <Typography variant="h3" sx={{ mt: 2 }}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid2 container direction="column" gap={2}>
          {filterOptions.map((option, index) => (
            <Grid2 item key={index}>
              <Typography
                color="#344054"
                fontSize={14}
                fontFamily="Inter"
                fontWeight={500}
                sx={{ mb: 1 }}
              >
                {option.label}
              </Typography>
              {option.type === "select" && (
                <Autocomplete
                  options={option.options}
                  getOptionLabel={(option) => option.label || option}
                  value={filterState[option.key] || null}
                  onChange={(_, value) => handleDropdownChange(option.key, value)}
                  renderInput={(params) => <TextField {...params} key={option.key} />}
                />
              )}
              {option.type === "multi-select" && (
                <Autocomplete
                  multiple
                  options={option.options}
                  getOptionLabel={(option) => option.key || option}
                  value={filterState[option.key] || null}
                  onChange={(_, value) => handleDropdownChange(option.key, value)}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox checked={selected} />
                      {option.label || option}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField {...params} label={option.label} placeholder="Select options" />
                  )}
                />
              )}
              {option.type === "date" && (
                <DateRangePickerComp
                  startDateProp={filterState[option.key]?.from_date}
                  endDateProp={filterState[option.key]?.to_date}
                  handleChange={(start, end) => handleDateChange(option.key, start, end)}
                />

              )}
            </Grid2>
          ))}
          <StyledFlexRowView style={{ justifyContent: "flex-end" }}>
            <CommonButton
              variant="outlined"
              color="#344054"
              border="#D0D5DD"
              fontWeight="600"
              value="Clear"
              padding={"10px 16px"}
              onClick={clearForm}
              setFilterState={setFilterState}
            // onClick={(event) => clearForm(event)}
            />
            <CommonButton
              variant="contained"
              color="white"
              background="#7F56D9"
              fontWeight="600"
              value={`Apply${count > 0 ? ` (${count})` : ""}`}
              padding="8px"
              onClick={() => onApply(filterState)}
            />
          </StyledFlexRowView>
        </Grid2>

      </DialogContent>
    </StyledDialog>
  );
};

export default FilterDialog;
